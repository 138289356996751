<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">ADI and Spec Grading Quiz</h2>

      <p class="mb-2">
        Argument Driven Inquiry (ADI) is or requires which of the following? Please select ALL
        correct responses.
      </p>

      <div class="checkbox-group pl-6 mb-1">
        <v-checkbox
          v-for="option in adiPartsOptions"
          :key="'pt-1-' + option.value"
          :value="option.value"
          class="mb-n4"
          v-model="inputs.adiParts"
          :label="option.text"
          dense
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UCIrvineAdiSpecGradingQuizQ5',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        adiParts: [],
      },
      adiPartsOptions: [
        {text: 'A teaching assistant acting as a facilitator', value: 'a'},
        {text: 'Active listening and participation', value: 'b'},
        {text: 'A right answer', value: 'c'},
        {text: 'Curiosity', value: 'd'},
        {text: 'Metacognition', value: 'e'},
        {text: 'Messiness', value: 'f'},
        {text: 'Social interaction to construct knowledge', value: 'g'},
        {text: 'Systematic evaluation of different ideas', value: 'h'},
      ],
    };
  },
};
</script>
<style scoped></style>
